import { Box, CardMedia, Typography } from '@mui/material';
import React, { useState,forwardRef } from 'react';
import { keyframes } from '@emotion/react';
import opportunity from '../../assets/images/opp.svg';
import Income from '../../assets/images/passiveIncome.svg';
import Early from '../../assets/images/EralyAccess.svg';
// import Transparency from '../../assets/images/Trans.svg';
import Support from '../../assets/images/support.svg';
import Virtual from '../../assets/images/VirtualIcon.svg';
import Equity from '../../assets/images/NonEquityIcon.svg';
import Debt from '../../assets/images/NonDebtIcon.svg';
import Transparency from '../../assets/images/transprency_new.svg';
import InvestorInfo from '../InvestorInfo/InvestorInfo';
import InvestorSlider from '../InvestorSlider/InvestorSlider';


const ImageUrl = [
  { id: 1, url: opportunity },
  { id: 2, url: Income },
  { id: 3, url: Early },
  { id: 4, url: Transparency },
  { id: 5, url: Support },
  { id: 6, url: Virtual },
  { id: 7, url: Equity },
  { id: 8, url: Debt },
];
// Duplicate the array for seamless scrolling
const duplicatedImages = [...ImageUrl, ...ImageUrl];

// Define the keyframes for the scrolling animation
const scrollAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-25%);
  }
`;


const Features = forwardRef((props, ref) => {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };
  return (
    <Box sx={{ background: '#fff' }} ref={ref}>
      <Box
        sx={{
          width: { xs: "100%", md: '90%' },
          height: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          p: { xs: 2, md: 10 },
          m: 'auto',
          gap: "24px",
        }}
      >
        <Box sx={{
          width: '100%', position: 'relative', overflow: 'hidden',
          display: "flex", flexDirection: "column", gap: "32px", cursor: 'pointer', alignItems: 'center'
        }} onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <Typography sx={{ fontWeight: 'bold', fontSize: { xs: "20px", md: '30px' }, color: '#28947F', textAlign: "center", }}>
            Key Features
          </Typography>
          <Box
            sx={{
              display: 'flex',
              animation: `${scrollAnimation} 20s linear infinite`,
              width: 'fit-content', gap: "32px", animationPlayState: isPaused ? 'paused' : 'running',
            }} >
            {duplicatedImages.map((item) => (
              <Box sx={{ width: { xs: "80px", md: "126px" }, height: { xs: "80px", md: "105" }, overflow: 'hidden' }} key={item.id}>
                <CardMedia
                  component="img"
                  sx={{ height: '100%', width: '100%', objectFit: 'contain' }}
                  image={item.url}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "32px" }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: { xs: "20px", md: "30px" }, color: '#28947F', textAlign: "center" }}>
            Why Investofarms?
          </Typography>
          <Box sx={{ width: "inherit", display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: { xs: "column", md: "row", sm:'column'} }}>
            <Box sx={{ width: { xs: "100%", md: "40%" }, }}>
              <InvestorInfo />
            </Box>

            <Box sx={{ width: { xs: "100%", md: "60%" , sm:"80%"} }}>
              <InvestorSlider />
            </Box>

          </Box>

        </Box>
      </Box>
    </Box>
  );
});

export default Features;
