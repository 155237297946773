import { Box, Typography } from '@mui/material';
import React, { forwardRef } from 'react'
import ContactDetails from '../ContactDetails/ContactDetails';

const ContactUs = forwardRef((props, ref) => {
  return (
    <Box sx={{ width:"90%", m:"auto"}} ref={ref}>
    <Box  
     sx={{
     
        height: 'inherit',
        display: 'flex',
        flexDirection:{xs:"column",md:"row"},
        alignItems:"center",
        p:{xs:1,md:8},
        m: 'auto',
        gap:"24px",
      }}>
    <Box sx={{ width:{xs:"100%",md:"100%"}, }}>
    <Typography sx={{ fontWeight:"bold", fontSize:{xs:"25px",md:"50px",}, color:"#28947F", p: 0,}}>Have More Questions? Lets Talk</Typography>
    <Typography sx={{width:{xs:"80%",md:"63%"}, fontSize:"12px", color:"#28947F",}}>We're here to help you with any queries about our investment opportunities. Reach out to us, and let's discuss how InvestoFarms can support your financial goals. Connect with our team today!</Typography>
    </Box>
    <Box sx={{ width:"100%", display:"flex", justifyContent:{xs:"flex-start",md:"flex-end"}}}>
    <ContactDetails />
    </Box>
    </Box>
    </Box>
  )
});
export default ContactUs;
