import React from 'react';
import { Box, Button, Typography } from  '@mui/material';
import { ReactComponent as InvestorIcon } from '../../assets/images/investorBannerIcon.svg';
import { useNavigate } from 'react-router-dom';

function InvestorBanner(){
  const navigate = useNavigate()

 const handleInvest = () =>
    {
      navigate("/disclaimer")
    }
  return (
   
    <Box sx={{ background: `linear-gradient(90deg, rgba(235, 185, 18, 1) 20%, rgba(235,185,18,0.20632002801120453) 100%)`,}}>
    <Box sx={{m:"auto", width:{xs:"98%",md:"85%"}, display:"flex", p:{xs: 1, md: 2}, justifyContent:"space-between",alignItems:"center" }}>
      <Box sx={{ width:{xs:"83%",md:"70%"}, display:"flex",gap:{xs:"8px",md:"16px"}, alignItems:"center",}} >  
        <InvestorIcon />
        <Typography sx={{fontSize:{xs:"16px",md:"40px"}, fontWeight:400, color:"#fff",width:{xs:"100%"}}}>Invest In Agriculture Today</Typography>
      </Box>
      <Box sx={{ width:{xs:"27%", md:"30%"}, display:"flex", justifyContent:"flex-end", alignItems:"center",}}>
      <Button variant="contained"   sx={{
        borderRadius:{xs:"20px",md:"30px"},
        p: 1,
        width: {xs:"100px",md:'120px'},
        height: '50px',
        backgroundColor: '#23766a',
        textTransform:"capitalize",
        '&:hover': {
          backgroundColor: '#EBB912',
          color: '#23766a', 
        },
      }}
      onClick={handleInvest}
      >
          Invest Now
        </Button>
      </Box>
      </Box>
    </Box>
  );
};

export default InvestorBanner;
