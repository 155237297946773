import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Grid, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/images/logo.svg";
import axios from 'axios'

function PaymentForm() {
  const [responseId, setResponseId] = React.useState("");
  const [responseState, setResponseState] = React.useState([]);
  useEffect(() => {
    let data = JSON.stringify({
      amount:  2500000,
      // amount:  100,
    })

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://backend-blush-rho.vercel.app/capture/${responseId}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    }

    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data))
    })
    .catch((error) => {
      console.log("error at", error)
    })
  }, [responseId])
  // responseId
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }

      document.body.appendChild(script);
    })
  }

  const handleRazorpayScreen = async(amount) => {
    const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js")

    if (!res) {
      handleUpdateGoogleSheet(formData.firstName+' '+formData.lastName, formData.email, formData.contactNumber, "Payment failed");
      alert("Some error at razorpay screen loading")
      return;
    }

    const options = {
      key: 'rzp_live_AkDBdW70PlCJ4T',
      amount: amount,
      currency: 'INR',
      name: "InvestoFarms",
      description: 'InvestoFarms Payment',
      image: logo,
      handler: function (response){
        setResponseId(response.razorpay_payment_id)
        handleUpdateGoogleSheet(formData.firstName+' '+formData.lastName, formData.email, formData.contactNumber, "Payment failed"+response.error.description);
      },
      prefill: {
        name: formData.firstName + ' ' + formData.lastName,
        email: formData.email,
        contact: formData.contactNumber,
      },
      notes: {
            address: 'Bengaluru South, Karnataka'
          },
          theme: {
            color: '#28947F'
          }
    }

    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
  }
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === 'contactNumber') {
      // Sanitize the input for contactNumber to only allow numbers and limit to 10 digits
      setFormData({ ...formData, [id]: value.replace(/[^0-9]/g, '').slice(0, 10) });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleKeyPress = (e) => {
    if (e.target.id === 'contactNumber' && !/[0-9]/.test(e.key)) {
      e.preventDefault(); // Prevent non-numeric input
    }
  };

  const handleUpdateGoogleSheet = (name, email, phone,status) => {
    // const formEle = document.querySelector("form");
    
    const formData = new FormData();
    formData.append('Email', "Name: "+name+" Email: "+email+" Mobile: "+phone+" Payment Status: "+status);
    // const formData = new FormData();
    formData.append('Email', email);
    fetch(
      "https://script.google.com/macros/s/AKfycbxpivRVuGkAtJ_70AN-f8i5e_7kL_hBlcup0IRm9n-VKEns7WhKyyZaLB03wo2RyPNqRw/exec",
      {
        method: "POST",
        body: formData
      }
    )
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "Enter First name.";
    if (!formData.lastName) newErrors.lastName = "Enter Last name.";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Enter Valid email.";
    if (!formData.contactNumber || !/^\d{10}$/.test(formData.contactNumber)) newErrors.contactNumber = "Enter Valid number.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    let data = JSON.stringify({
      amount: 2500000,
      // amount: 100,
      currency: "INR"
    })

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      // url: "http://localhost:8000/orders",
      url: "https://backend-blush-rho.vercel.app/orders",
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true'
      },
      data: data
    }

    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data))
      handleRazorpayScreen(2500000)
    })
    .catch((error) => {
      console.log("error at", error)
    })

    // console.log(formData.firstName+' '+formData.lastName, formData.email, formData.contactNumber)
    // handleUpdateGoogleSheet(formData.firstName+' '+formData.lastName, formData.email, formData.contactNumber, "Payment failed");
    // const orderId = Date.now();
    // const max = 10;
    // const randomIntegerRecipt = Math.floor(Math.random() * max);
    // const order = {
    //   "id": orderId,
    //   "entity": "order",
    //   "amount": 100,
    //   "amount_paid": 0,
    //   "amount_due": 10000,
    //   "currency": "INR",
    //   "receipt": "receipt#",
    //   "status": "created",
    //   "attempts": 0,
    //   "created_at": 1619091626
    // }
    

    // const options = {
    //   key: 'rzp_test_GcZZFDPP0jHtC4', 
    //   // amount: order.amount, 
    //   // currency: order.currency,
    //   name: 'Investo Farms',
    //   description: 'InvestoFarms Payment',
    //   image: logo, 
    //   handler: function (response) {
    //     handleUpdateGoogleSheet(formData.firstName+' '+formData.lastName, formData.email, formData.contactNumber, "Payment failed"+response.error.description);
      
    //     Swal.fire({
    //       icon: 'success',
    //       text: 'Payment Successful!',
    //       confirmButtonText: 'OK',
    //       confirmButtonColor: "#28947F",
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         navigate("/");
    //       }
    //     });
    //   },
    //   prefill: {
    //     name: formData.firstName + ' ' + formData.lastName,
    //     email: formData.email,
    //     contact: formData.contactNumber,
    //   },
    //   notes: {
    //     address: 'Bengaluru South, Karnataka'
    //   },
    //   theme: {
    //     color: '#28947F'
    //   }
    // };
    // const rzp1 = new window.Razorpay(options);
    
    // rzp1.on('payment.failed', function (response) {
    //   handleUpdateGoogleSheet(formData.firstName+' '+formData.lastName, formData.email, formData.contactNumber,"Payment failed"+response.error.description);
    //   rzp1.close();
    //   window.location.reload();
      
    // });
    // rzp1.open();
  };

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 4,
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2} sx={{ width:{xs:"100%",md:"40%"} }}>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: "#28947F" }}>First Name</Typography>
          <TextField
            size='small'
            required
            id="firstName"
            placeholder="Enter First Name"
            InputProps={{ style: { fontSize: '10px' } }}
            sx={{
                background:"#D1E1DC",
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#23766a',
                  },
                  '&:hover fieldset': {
                    borderColor: '#28947F',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#28947F',
                  },
                },
              }}
            variant="outlined"
            fullWidth
            value={formData.firstName}
            onChange={handleChange}
          />
          {errors.firstName && <Typography variant="caption" color="error">{errors.firstName}</Typography>}
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: "#28947F" }}>Last Name</Typography>
          <TextField
            size='small'
            required
            id="lastName"
            placeholder="Enter Last Name"
            InputProps={{ style: { fontSize: '10px' } }}
            sx={{
                background:"#D1E1DC",
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#23766a',
                  },
                  '&:hover fieldset': {
                    borderColor: '#28947F', 
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#28947F', 
                  },
                },
              }}
            variant="outlined"
            fullWidth
            value={formData.lastName}
            onChange={handleChange}
          />
          {errors.lastName && <Typography variant="caption" color="error">{errors.lastName}</Typography>}
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: "#28947F" }}>E-mail ID</Typography>
          <TextField
            required
            size='small'
            id="email"
            placeholder="Enter E-mail ID"
            InputProps={{ style: { fontSize: '10px' } }}
            sx={{
                background:"#D1E1DC",
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#23766a',
                  },
                  '&:hover fieldset': {
                    borderColor: '#28947F', 
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#28947F', 
                  },
                },
              }}
            variant="outlined"
            fullWidth
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <Typography variant="caption" color="error">{errors.email}</Typography>}
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: "#28947F" }}>Contact Number</Typography>
          <TextField
            required
            size='small'
            id="contactNumber"
            placeholder="Enter Contact Number"
            type="tel"
            inputProps={{ maxLength: 10 }} // Limits input to 10 characters
            InputProps={{ style: { fontSize: '10px' } }}
            sx={{
                background:"#D1E1DC",
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#23766a',
                  },
                  '&:hover fieldset': {
                    borderColor: '#28947F', 
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#28947F', 
                  },
                },
              }}
            variant="outlined"
            fullWidth
            value={formData.contactNumber}
            onChange={handleChange}
            onKeyPress={handleKeyPress} // Prevent non-numeric input
          />
          {errors.contactNumber && <Typography variant="caption" color="error">{errors.contactNumber}</Typography>}
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{
          mt:{xs:4,md:2},
          borderRadius: "25px",
          backgroundColor: '#23766a',
          textTransform: "capitalize",
          width: "220px",
          height: "50px",
          '&:hover': {
            backgroundColor: '#EBB912',
            color: '#23766a',
          },
        }}
      >
        Proceed to Payment
      </Button>
    </Box>
  );
}

export default PaymentForm;
