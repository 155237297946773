import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { ReactComponent as WhatsAppIcon } from '../../assets/images/WhatsappIcon.svg';
import { ReactComponent as CallIcon } from '../../assets/images/CallingIcon.svg';

function ContactDetails() {
  const handleWhatsAppClick = () => {
    window.location.href = 'https://wa.me/919353511090?text=Hi%2C%0ACan%20we%20connect?'; // Replace with your WhatsApp number
  };

  const handleCallClick = () => {
    window.location.href = 'tel:+919353511090'; // Replace with your phone number
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: '16px', md: '32px' },
      }}
    >
      <Button
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '8px',
          width:"250px",
          padding: '8px 16px',
          borderRadius: '50px',
          background: 'rgba(40, 148, 127, 0.15)',
          border: '1px solid #28947F',
          cursor: 'pointer',
          textTransform: 'none',
        }}
        onClick={handleWhatsAppClick}
      >
        <WhatsAppIcon />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '4px' }}>
          <Typography sx={{ color: '#28947F', fontWeight: 'bold' }}>Lets Chat</Typography>
          <Typography sx={{ fontSize: '12px', color: '#5A5A5A' }}>We'll reply in 1 Hour.</Typography>
        </Box>
      </Button>
      <Button
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '8px',
          width:"250px",
          padding:'8px 16px',
          borderRadius: '50px',
          background: 'rgba(40, 148, 127, 0.15)',
          border: '1px solid #28947F',
          cursor: 'pointer',
          textTransform: 'none',
        }}
        onClick={handleCallClick}
      >
        <CallIcon />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '4px' }}>
          <Typography sx={{ color: '#28947F', fontWeight: 'bold' }}>+91 93535 11090</Typography>
          <Typography sx={{ fontSize: '12px', color: '#5A5A5A' }}>Mon - Sat 10 AM to 7 PM</Typography>
        </Box>
      </Button>
    </Box>
  );
}

export default ContactDetails;
