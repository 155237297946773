import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import logo from "../../assets/images/logo.svg";
import logoMobile from "../../assets/images/LogoMobile.svg";
import { CardMedia } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import certificateImage from "../../assets/images/make_in_india_cer.png";
import newgif from "../../assets/images/new-gif-icon-5.gif"
import Swal from 'sweetalert2';
import "./style.css";

interface Props {
  onContactUsClick: () => void;
  handleAboutClick: () => void;
}

const drawerWidth = 240;
const navItems = ['Home', 'About', 'FAQ', 'Contact Us', "Certificate"];

export default function Navbar(props: Props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { onContactUsClick, handleAboutClick } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleLogoClick = () =>
    {
      navigate("/");
    }

  const handleNavItemClick = (item: string) => {
    if (item === 'Home') {
      navigate('/');
    } else if (item === 'About') {
      if (location.pathname === '/faq' || location.pathname === '/refund-policy' || location.pathname === '/privacy-policy' || location.pathname === '/terms-and-conditions' || location.pathname === "/disclaimer") {
        navigate('/');
      } else {
        handleAboutClick();
      }
    } else if (item === 'FAQ') {
      navigate("/faq");
    } else if (item === 'Contact Us') {
      if (location.pathname === '/faq' || location.pathname === '/refund-policy' || location.pathname === '/privacy-policy' || location.pathname === '/terms-and-conditions'|| location.pathname === "/disclaimer") {
        window.open( 'https://wa.me/919353511090', '_blank');
      } else {
        onContactUsClick();
      }
    }else if (item === 'Certificate') {
      Swal.fire({
        imageUrl: certificateImage,
      imageAlt: 'Your Certificate',
      showCloseButton: true,
      showConfirmButton: false,
      heightAuto: false, 
      customClass: {
        popup: 'custom-swal-popup', 
      },
      });
    }
    handleDrawerToggle();
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box sx={{ height: "70px", width: "180px", background: "transparent", ml: 2, cursor:"pointer" }} onClick={handleLogoClick}>
        <CardMedia
          sx={{ height: "100%", width: "100%", objectFit: "cover" }}
          image={logo}
          title="investo_logo"
        />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center', }} onClick={() => handleNavItemClick(item)}>
              <ListItemText primary={item} sx={{ color: '#28947F' }} />
              {item === 'Certificate' && (
          <Box sx={{
            position: 'absolute',
            top: -2, 
            right: 45, 
            width: 30, 
            height: 30, 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
}}>
            <img 
              src={newgif}
              alt="New Badge" 
              style={{ width: '100%', height: '100%' }} 
            />
          </Box>
        )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = undefined;

  return (
    <Box sx={{ display: 'flex', height: "80px" }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ border: '1px solid white', background: "#fff", boxShadow: "none", p: 1 }}>
        <Toolbar sx={{ border: '1px solid white', width: { xs: "100%", md: "86%" }, m: "auto" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 1, display: { sm: 'none' } }}>
            <MenuIcon sx={{ color: '#28947F' }} />
          </IconButton>
          <Box
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block', color: '#28947F' } }}>
            <Box sx={{ height: "70px", width: "180px", background: "transparent" }} onClick={handleLogoClick}>
              <CardMedia
                sx={{ height: "100%", width: "100%", objectFit: "cover", cursor: "pointer" }}
                image={logo}
                title="investo_logo"
              />
            </Box>
          </Box>
          {isDesktop && isTablet && isSmallScreen && (
            <Box sx={{ m: 'auto' }}>
              <Box sx={{ height: "50px", width: "120px", background: "transparent" }}>
                <CardMedia
                  sx={{ height: "100%", width: "100%", objectFit: "contain" }}
                  image={logoMobile}
                  title="investo_logo"
                />
              </Box>
            </Box> 
          )}
        <Box sx={{ display: { xs: 'none', sm: 'block', } }}>
  {navItems.map((item) => (
    <Box key={item} className="badge-container">
      <Button
        sx={{ color: '#28947F', position: 'relative' }}
        onClick={() => handleNavItemClick(item)}
      >
        {item}
        {item === 'Certificate' && (
         <Box className="badge">
          <img 
            src={newgif}
            alt="New Badge" 
            style={{ width: '100%', height: '100%' }} 
          />
        </Box>
        )}
      </Button>
    </Box>
  ))}
</Box>

        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}
