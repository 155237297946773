import {Box,useMediaQuery, useTheme } from '@mui/material';
import Navbar from '../components/Navbar';
import Home from '../pages/Home';
import Footer from '../components/Footer/Footer';
import Features from '../components/Features/Features';
import InvestorJourney from '../components/InvestorJourney/InvestorJourney';
import { Unicef } from '../components/Unicef/Unicef';
import InvestorBanner from '../components/InvestorBanner/InvestorBanner';
import ContactUs from '../components/ContactUs/ContactUs';
import { useRef } from 'react';
import InvestmentCalculator from '../components/ InvestmentCalculator/ InvestmentCalculator';
function LandingPage(){
      const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const footerRef = useRef(null)
  const contactUsRef = useRef(null);
  const aboutRef = useRef(null);

  // const handleContactUsClick = () => {
  //   window.location.href = 'https://wa.me/919353511090';
  //   // alert("you click contact us")
  //   if (footerRef.current) {
  //     footerRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };
 
  const handleContactUsClick = () => {
    window.open( 'https://wa.me/919353511090', '_blank');
    if (!contactUsRef.current) {
      contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }; 
  const handleAboutClick = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }; 

  return (
  <Box>
  <Navbar onContactUsClick={handleContactUsClick} handleAboutClick={handleAboutClick}/>
  <Home />
 
  <Box sx={{background: `linear-gradient(90deg, rgba(209,230,226,1) 0%, rgba(234,238,236,1) 95%)`,}}>
  <Features ref={aboutRef}/>
   <InvestorJourney />
   <Box sx={{ display: "flex", height: "inherit", width: { xs: "100%", md: "90%" }, m: "auto", flexDirection: { xs: "column-reverse", md: "row" } }}>
   <InvestmentCalculator />
   </Box>
  <Unicef />
  <ContactUs ref={contactUsRef}/>
  </Box>
  <InvestorBanner />
  <Footer ref={footerRef} />
  </Box>
  )
}
export default LandingPage;
