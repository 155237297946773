// import React, { useState, useEffect } from 'react';
// import Typography from '@mui/material/Typography';

// const Typewriter = () => {
//     const textArray = ['Tax Free |', 'Tax Free | Stable Returns | ', 'Tax Free | Stable Returns |  Small Ticket Size '];
//     const [displayedText, setDisplayedText] = useState('');
//     const [isDeleting, setIsDeleting] = useState(false);
//     const [loopIndex, setLoopIndex] = useState(0);
//     const [charIndex, setCharIndex] = useState(0);

//     useEffect(() => {
//         const handleType = () => {
//             setDisplayedText(textArray[loopIndex].substring(0, charIndex + (isDeleting ? -1 : 1)));
//             setCharIndex(charIndex + (isDeleting ? -1 : 1));

//             if (!isDeleting && charIndex === textArray[loopIndex].length) {
//                 setTimeout(() => setIsDeleting(true), 500); // Pause at the end of typing
//             } else if (isDeleting && charIndex === 0) {
//                 setIsDeleting(false);
//                 setLoopIndex((loopIndex + 1) % textArray.length);
//             }
//         };

//         const typingSpeed = isDeleting ? 50 : 150;
//         const timeout = setTimeout(handleType, typingSpeed);

//         return () => clearTimeout(timeout);
//     }, [charIndex, isDeleting, loopIndex]);

//     return (
//         <Typography  sx={{color:"#23766a", fontWeight:"bold", fontSize:{xs:"16px",md:"50px"},display: 'inline-block', width:"100%"}}>
//             {displayedText}
//             <span style={{ animation: `${blinkAnimation} 1s linear infinite`, backgroundColor: 'orange', width: '0.15em', height: '1em', display: 'inline-block', marginLeft:"5px" }}></span>
            
//         </Typography>
//     );
// };


// const blinkAnimation = `
//     @keyframes blink {
//         0%, 100% { opacity: 1; }
//         50% { opacity: 0; }
//     }
// `;

// export default Typewriter;
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

const Typewriter = () => {
    const textArray = ['Tax Free ', ' Stable Returns ', ' Small Ticket Size '];
    const [displayedText, setDisplayedText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopIndex, setLoopIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);

    useEffect(() => {
        const handleType = () => {
            if (!isDeleting) {
                // Typing
                setDisplayedText(prevText => {
                    const newText = textArray[loopIndex].substring(0, charIndex + 1);
                    if (newText === textArray[loopIndex]) {
                        // Whole phrase typed, start deleting
                        setTimeout(() => setIsDeleting(true), 500); // Pause at the end of typing
                    }
                    return newText;
                });
                setCharIndex(charIndex + 1);
            } else {
                // Deleting
                setDisplayedText(prevText => {
                    const newText = textArray[loopIndex].substring(0, charIndex - 1);
                    if (newText === '') {
                        // Whole phrase deleted, move to next phrase
                        setIsDeleting(false);
                        setLoopIndex((loopIndex + 1) % textArray.length);
                    }
                    return newText;
                });
                setCharIndex(charIndex - 1);
            }
        };

        const typingSpeed = isDeleting ? 50 : 150;
        const timeout = setTimeout(handleType, typingSpeed);

        return () => clearTimeout(timeout);
    }, [charIndex, isDeleting, loopIndex]);

    return (
        <Typography  sx={{color:"#23766a", fontWeight:"bold", fontSize:{xs:"24px",md:"50px"},display: 'inline-block', width:"100%"}}>
            {displayedText}
            <span style={{ animation: `${blinkAnimation} 1s linear infinite`, backgroundColor: 'orange', width: '0.15em', height: '1em', display: 'inline-block', marginLeft:"2px" }}></span>
        </Typography>
    );
};


const blinkAnimation = `
    @keyframes blink {
        0%, 100% { opacity: 1; }
        50% { opacity: 0; }
    }
`;

export default Typewriter;
