import { Box, Typography } from '@mui/material'
import React from 'react'
import Calculator from '../Calculator/Calculator'
// import InvestmentCalculator from '../InvestmentCalculator/InvestmentCalculator'
function InvestmentCalculator() {
  return (
    <Box>
    <Box sx={{display:"flex", width:{xs:"100%",md:"90%"}, gap:{xs:"0px",md:"8px"}, flexDirection:{xs:"column-reverse", md:"row"}}}>
   <Box sx={{ width:"100%",}}>
   <Calculator />
   </Box>
   <Box sx={{ m:"auto",display:"flex",flexDirection:"column",gap:"16px",width:{xs:"100%",md:"100%"}, p: 2}}>
   <Box sx={{ display:"flex",flexDirection:"column", gap:{xs:"12px",md:"16px"}}}>
   <Typography sx={{fontSize:{xs:"16px",md:"25px"}, fontWeight:"bold", color:"#28947F"}}>Calculate Potential Tax-Free Income from Farmland Investment: </Typography>
   <Typography sx={{ fontSize:{xs:"11px",md:"12px"}}}>Estimate your earnings based on projected returns and potential tax benefits associated with farmland investment (consult a tax advisor for specific details).</Typography>
   </Box>
   <Box sx={{ display:"flex",flexDirection:"column", gap:{xs:"12px",md:"16px"}}}>
   <Typography sx={{fontSize:{xs:"16px",md:"25px"}, fontWeight:"bold", color:"#28947F"}}>See How Much You Could Earn with Tax-Favored Managed Farms: </Typography>
   <Typography sx={{ fontSize:{xs:"11px",md:"12px"}}}>Use our calculator to estimate your potential earnings considering the possibility of tax-free returns from professionally managed farms.</Typography>
   </Box>
   </Box>
   </Box>
   <Box sx={{ p: 2,  width:"98%"}}>
   <Typography sx={{fontSize:"11px", textAlign:{xs:"start",md:"center"}}}><span style={{fontSize:"12px", fontWeight:"bold"}}>Disclaimer:</span> This calculator provides an estimate and does not guarantee actual returns.  Tax implications can vary depending on individual circumstances.  Please consult a tax advisor for personal tax advice.</Typography>
   </Box>
   </Box>
  )
}
export default  InvestmentCalculator
