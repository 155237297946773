import {Box } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import FAQ from './pages/FAQ';
import TermsAndConditions from './pages/TermsAndConditions';
import Privacy from './pages/Privacy';
import Refund from './pages/Refund';
import Disclaimer from './pages/Disclaimer';
function App() {
  return (
  <Box sx={{height:"100vh", overflowY:"scroll"}}>
  <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/faq" element={<FAQ /> } />
      <Route path="/terms-and-conditions" element={<TermsAndConditions /> } />
      <Route path="/refund-policy" element={<Refund /> } />
      <Route path="/disclaimer" element={<Disclaimer /> } />
      <Route path="/privacy-policy" element={<Privacy /> } />
    </Routes>
  </Box>
  );
}

export default App;
