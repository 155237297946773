import { Box, Typography, Button, Stack } from '@mui/material'
import React from 'react'
import heroImage from "../assets/images/home.png";
import { Helmet } from 'react-helmet';
import { ReactComponent as Affordable } from '../assets/images/AffordableIcon.svg';
import Typing from '../components/Typing/Typing';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate()
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/919353511090', '_blank'); // Replace with your WhatsApp number
  };
  const handleInvest = () =>
    {
      navigate("/disclaimer")
    }
  return (
    <><Helmet>
      <title>InvestoFarms</title>
      <meta name="description" content="This is the home page description for SEO." />
      <meta name="keywords" content="home, affordable, typing, react" />
      {/* <meta name="author" content="Your Name or Company" /> */}
    </Helmet><Box sx={{
      backgroundImage: `url(${heroImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: { xs: "auto", md: "inherit" },
    }}>
        <Box
          sx={{
            width: { xs: "100%", md: "90%" },
            height: { xs: "auto", md: "inherit" },
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "flex-start" },
            p: { xs: 2, md: 10 },
            m: "auto",
            gap: { xs: "16px", md: "8px" },
          }}
        >
          <Affordable />
          <Typography variant='h2' color="#EBB912" fontWeight="bold" width="100%" sx={{ fontSize: { xs: "32px", md: "55px" } }}>Invest in Your Future</Typography>
          <Typing />
          <Typography sx={{ fontSize: "14px", width: { xs: "80%", md: "30%" }, }}>Investofarms offers a unique opportunity to invest in farmland without the burden of ownership or debt.</Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ marginTop: { xs: "10px", md: '30px' } }}
          >
            <Button variant="contained" sx={{
              borderRadius: '40px',
              p: 1,
              width: '130px',
              height: '50px',
              backgroundColor: '#23766a',
              textTransform: "capitalize",
              '&:hover': {
                backgroundColor: '#EBB912', // Background color on hover
                color: '#23766a', // Text color on hover
              },
            }}
            onClick={handleInvest}
            >
              Invest Now
            </Button>
            <Button variant="contained" sx={{
              borderRadius: '40px',
              p: 1,
              width: '130px',
              height: '50px',
              textTransform: "capitalize",
              backgroundColor: '#23766a',
              '&:hover': {
                backgroundColor: '#EBB912', // Background color on hover
                color: '#23766a', // Text color on hover
              },
            }}
              onClick={handleWhatsAppClick}>
              Contact Us
            </Button>
          </Stack>
        </Box>
      </Box></>
  )
}
export default Home 
