import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import clsx from 'clsx';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Divider } from '@mui/material';

const steps = [
  { number: 1, title: 'Discovery', description: 'The potential investor stumbles upon an InvestoFarms social media ad or blog post that highlights achieving FIRE (Financial Independence, Retire Early) through farmland investments.' },
  { number: 2, title: 'Initial Exploration', description: 'Intrigued, the potential investor visits the InvestoFarms website and explores the homepage. Clear information about non-equity investment, potential for passive income, and tax-free returns grabs their attention.' },
  { number: 3, title: 'Deeper Dives', description: 'For a more thorough understanding, the investor had a one-on-one meeting with our experts who shared in-depth details.' },
  { number: 4, title: 'Calculating Investment Potential', description: 'An InvestoFarms investment calculator tool empowers the potential investor to estimate potential returns based on their desired investment amount and timeframe.' },
  { number: 5, title: 'Addressing Concerns', description: 'The potential investor efficiently utilizes the websites FAQ section or initiates a live chat with our experts answers and clarification.' },
  { number: 6, title: 'Decision Making', description: 'Feeling confident and informed, the potential investor decides to proceed with investing. They carefully choose a specific farm project that aligns with their investment goals and risk tolerance.' },
  { number: 7, title: 'Investment Process', description: 'A secure online portal guides the potential investor through the investment process. They electronically review the investment agreement and proceed to the payment gateway for a seamless transaction.' },
  { number: 8, title: 'Onboarding', description: 'Following a successful payment, the potential investor receives a welcome email with login credentials for their dedicated investor portal. This portal provides access to investment details, performance reports, farm updates, and communication channels, keeping them informed and engaged.' },
];

const stepSequence = [0, 1, 2, 3, 4, 5, 6, 7];

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    '& .MuiStepConnector-line': {
      borderColor: 'orange',
      height: "20px",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    '& .MuiStepConnector-line': {
      borderColor: 'orange',
      height: "20px",
    },
  },
  [`& .MuiStepConnector-line`]: {
    borderWidth: "3px",
  },
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: ownerState.completed ? 'orange' : " rgba(16,101,84,1) ",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiSvgIcon-root': {
    color: ownerState.completed ? 'orange' : 'inherit',
  },
}));

function CustomStepIcon(props) {
  const { active, completed, icon } = props;
  return (
    <CustomStepIconRoot ownerState={{ active, completed }}>
      {completed ? <FiberManualRecordIcon /> : <RadioButtonCheckedIcon />}
    </CustomStepIconRoot>
  );
}

function InvestorJourney() {
  const [activeStep, setActiveStep] = useState(0);
  const stepsPerScreen = window.innerWidth < 600 ? 2 : 4;

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % stepSequence.length);
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ width: { xs: "100%", md: "90%" }, m: "auto", p: 1, display: "flex", flexDirection: "column", gap: "40px", mt: "40px", }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", alignItems: "center" }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '24px', md: '30px' }, color: '#28947F' }}>The InvestoFarms Journey</Typography>
        <Divider sx={{ borderWidth: 1, background: "#28947F", width: { xs: '80%', md: '30%', sm:'39%' } }} />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
        {Array.from({ length: Math.ceil(steps.length / stepsPerScreen) }).map((_, startIndex) => (
          <Stepper
            key={startIndex}
            activeStep={stepSequence[activeStep] - startIndex * stepsPerScreen >= 0 && stepSequence[activeStep] - startIndex * stepsPerScreen < stepsPerScreen ? stepSequence[activeStep] - startIndex * stepsPerScreen : -1}
            alternativeLabel
            connector={<CustomStepConnector />}
            sx={{ width: "100%", flexWrap: { xs: 'wrap', md: 'nowrap' }, }}
          >
            {steps.slice(startIndex * stepsPerScreen, startIndex * stepsPerScreen + stepsPerScreen).map((step, index) => (
              <Step key={index} completed={activeStep > index + startIndex * stepsPerScreen}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <Box sx={{ width: "inherit", display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "8px" }}>
                    <Typography variant="h6">{step.number}</Typography>
                    <Typography sx={{ fontSize: { xs: "12px", md: "18px" }, fontWeight: activeStep === index + startIndex * stepsPerScreen ? "bold" : "500", width: "inherit", color: activeStep === index + startIndex * stepsPerScreen ? "#1D4D4F" : "inherit", }}>{step.title}</Typography>
                    <Typography sx={{ fontSize: { xs: "0", md: "12px" }, fontWeight: activeStep === index + startIndex * stepsPerScreen ? "600" : "none", width: "inherit", textAlign: { xs: 'center', md: 'inherit' }, display: { xs: 'none', md: 'block' } }}>{step.description}</Typography>
                  </Box>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        ))}
      </Box>
    </Box>
  );
}

export default InvestorJourney;
