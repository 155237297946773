import React, { useState } from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import UnicefIcon from '../../assets/images/unicefIcon.svg';
import ZeroHunger from '../../assets/images/ZeroHunger.svg';
import OneHunger from '../../assets/images/OneHunger.svg';
import GoodHealthOne from '../../assets/images/GoodHealthOne.svg';
import GoodHealth from '../../assets/images/GoodHealth.svg';
import CleanWater from '../../assets/images/CleanWater.svg';
import CleanWaterOne from '../../assets/images/CleanWaterOne.svg';
import CleanEnergy from '../../assets/images/CleanEnergy.svg';
import CleanEnergyOne from '../../assets/images/CleanEnergyOne.svg';
import LandLife from '../../assets/images/LifeLand.svg';
import LandLifeOne from '../../assets/images/LifeLandOne.svg';
import DecentWrok from '../../assets/images/DecentWrok.svg';
import DecentWrokOne from '../../assets/images/DecentWrokOne.svg';
import Industry from '../../assets/images/Industry.svg';
import IndustryOne from '../../assets/images/IndustryOne.svg';
import Responsible from '../../assets/images/Responsible.svg';
import ResponsibleOne from '../../assets/images/ResponsibleOne.svg';
import Climate from '../../assets/images/Climate.svg';
import ClimateOne from '../../assets/images/ClimateOne.svg';
import Leave from '../../assets/images/Leave.svg';
import LeaveOne from '../../assets/images/LeaveOne.svg';
import UnicefNewLogo from '../../assets/images/unicefNewLogo.svg';




const UnicefData = [
  { id: 1, name: "Zero Hunger", icon: ZeroHunger,icon1:OneHunger,hoverColor: "#DDA639" },
  { id: 2, name: "Good Health & Well Being", icon: GoodHealth ,icon1:GoodHealthOne,hoverColor: "#4C9F38" },
  { id: 3, name: "Clean Water & Sanitation", icon: CleanWater,icon1:CleanWaterOne,hoverColor: "#42BDC2"},
  { id: 4, name: "Affordable & Clean Energy", icon: CleanEnergy,icon1:CleanEnergyOne,hoverColor: "#F9C30D" },
  { id: 5, name: "Life On Land", icon: LandLife,icon1:LandLifeOne,hoverColor: "#56C02B" },
  { id: 6, name: "Decent Work & Economic Growth", icon: DecentWrok,icon1:DecentWrokOne,hoverColor: "#A21942" },
  { id: 7, name: "Industry, Innovation & Infrastructure", icon: Industry,icon1:IndustryOne,hoverColor: "#F56925"},
  { id: 8, name: "Responsible Consumption & Production", icon: Responsible,icon1:ResponsibleOne,hoverColor: "#BF8B2E" },
  { id: 9, name: "Climate Action",  icon: Climate,icon1:ClimateOne,hoverColor: "#3F7E45" },
  { id: 10, name: "Leave No One Behind", icon: Leave,icon1:LeaveOne,hoverColor: "#8699EB"},
];

export const Unicef = () => {
  const [hoveredIcons, setHoveredIcons] = useState({});

  const handleMouseEnter = (id, icon1) => {
    setHoveredIcons(prevState => ({
      ...prevState,
      [id]: icon1,
    }));
  };

  const handleMouseLeave = (id) => {
    setHoveredIcons(prevState => ({
      ...prevState,
      [id]: null,
    }));
  };

  return (
    <Box>
      <Box
        sx={{
          width:{xs:"100%",md:"90%"},
          height: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          p:{xs:1,md:10},
          m: 'auto',
          gap: "44px",
        }}
      >
        <Box sx={{ width:{xs:"85%",md:"70%"}, display: "flex", gap:{xs:"4px",md:"8px"}, alignItems: "center", justifyContent: "center", m:"auto",}}>
          <Box sx={{ width:{xs:"60px",md:"130px"}, height:{ xs:"60px",md:"130px"}, overflow: 'hidden' }}>
            <CardMedia
              component="img"
              sx={{ height: '100%', width: '100%', objectFit: 'contain', }}
              image={UnicefNewLogo}
            />
          </Box>
          <Typography sx={{ fontSize:{xs:"18px",md:"50px",}, fontWeight: "bold", color: "#28947F",  textAlign:"center", width:{xs:"80%",md:"100%"}}}>We Support Sustainable Development Goals </Typography>
        </Box>
        
        <Box sx={{ display:"grid", gridTemplateColumns:{xs:"repeat(3, 1fr)",md:"repeat(5, 1fr)"}, gap:{xs:"16px",md:"32px"} }}>
          {UnicefData.map((item) => ( 
            <Box
              key={item.id}
              sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px", cursor:"pointer" }}
              onMouseEnter={() => handleMouseEnter(item.id, item.icon1)}
              onMouseLeave={() => handleMouseLeave(item.id)}
            >
              <Box sx={{ width:{xs:"80px",md:"130px"}, height:{xs:"80px",md:"130px"},display:"flex", flexDirection:"column", alignItems:"center", gap:"8px" }}>
                <CardMedia
                  component="img"
                  sx={{ height: '100%', width: '100%', objectFit: 'cover' }}
                  image={hoveredIcons[item.id] || item.icon}
                />
              </Box>
              <Typography sx={{fontSize:{xs:"10px",md:"14px"}, fontWeight:"700", color: hoveredIcons[item.id] ? item.hoverColor : '#28947F', width:"inherit", textAlign:"center",}}>{item.name}</Typography> 
            </Box>
          ))} 
        </Box>
      </Box>
    </Box>
  );
};
