// import React from 'react';
import React, { useState } from 'react';
// import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, TextField, Button, Link, IconButton, Divider, Input, CardMedia } from '@mui/material';
import { ReactComponent as FacebookIcon } from '../../assets/images/FacebookIcon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/TwitterIcon.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/instagramIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/images/YoutubeIcon.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/images/LinkedIn.svg';
import footerlogo from "../../assets/images/FooterLogo.png"
const Footer = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailSubmit, setEmailSubmit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/investo.farms/", "_blank");
  };

  const handleLinkedinClick = () => {
    window.open("https://www.linkedin.com/company/investofarms/", "_blank");
  };

  const handleFacebookClick = () => {
    window.open("https://www.facebook.com/profile.php?id=61558569963611", "_blank");
  };
  
  const handleUpdateGoogleSheet = () => {
    // const formEle = document.querySelector("form");
    const formData = new FormData();
    formData.append('Email', email);
    fetch(
      "https://script.google.com/macros/s/AKfycbxpivRVuGkAtJ_70AN-f8i5e_7kL_hBlcup0IRm9n-VKEns7WhKyyZaLB03wo2RyPNqRw/exec",
      {
        method: "POST",
        body: formData
      }
    )
  };

  const handleYoutubeClick = () => {
    window.open('https://www.youtube.com/@investofarms/' , "_blank"); // Replace with your WhatsApp number
  };
  const handleUpdateSheet = (e) => {
    const formEle = document.querySelector("form");
    const formData = new FormData(formEle);
    fetch("https://script.google.com/macros/s/AKfycbxpivRVuGkAtJ_70AN-f8i5e_7kL_hBlcup0IRm9n-VKEns7WhKyyZaLB03wo2RyPNqRw/exec",
      {
        method: "POST",
        body: formData
      }
    )

    window.open('https://wa.me/919353511090', "_blank"); // Replace with your WhatsApp number
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Reset email submission status when the email changes
    setEmailSubmit(false);
  };

  const handleButtonClick = () => {
    // Regular expression for validating an Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setEmailError(false);
      setEmailSubmit(true);
      handleUpdateGoogleSheet()
      setEmail("")
      Swal.fire({
        title: 'Thank you for subscribing!',
        text: 'We will keep you updated.',
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: "#28947F",
        
      });
    } else {
      setEmailError(true);
      setEmailSubmit(true);
    }
  };
  
  const handlePrivacyClick = () =>
    {
      navigate("/privacy-policy");
    }
    const termsAndConditionsClick = () =>
      {
        navigate("/terms-and-conditions");
      }
      const faqClick = () =>
        {
          navigate("/faq");
        }
        const handleRefundClick = () =>
          {
            navigate("/refund-policy");
          }
        // handleRefundClick
        const aboutClick = () =>
          {
            navigate("/");
          }
  return (
    <Box
      sx={{ backgroundColor: '#072E0A', display: "flex", flexDirection: "column", gap: { xs: "25px", md: "50px" } }}
    >
      <Box sx={{ width: { xs: "100%", md: "90%", }, p: { xs: 2, md: 6 }, height: "inherit", display: "flex", justifyContent: "space-around", flexDirection: { xs: "column", md: "row" }, mt: { xs: 1, md: 0 }, m: "auto", }}>
        <Box sx={{ width: { xs: "100%", md: "65%" }, display: "flex", flexDirection: "column", gap: "16px", }}>
          <Box sx={{ width: { sm: '40%', xs: "65%", md: "70%" }, height: "50px" }}>
            <CardMedia
              component="img"
              sx={{ height: '100%', width: '100%', objectFit: 'cover' }}
              image={footerlogo}
            />
          </Box>
          <Typography sx={{ fontSize: "14px", color: "#fff", width: { xs: "90%", md: "65%", sm: '40%' } }}>InvestoFarms offers a unique opportunity to invest in farmland without the burden of ownership or debt.</Typography>
          <Divider sx={{ background: "#fff", width: "inherit" }} />
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <IconButton sx={{ p: 0 }}>
              <FacebookIcon onClick={handleFacebookClick}/>
            </IconButton>
            <IconButton sx={{ p: 0 }}>
              <LinkedinIcon onClick={handleLinkedinClick} />
            </IconButton>
            <IconButton sx={{ display: "flex", alignItems: "center", mt: 1, p: 0 }} onClick={handleInstagramClick}>
              <InstagramIcon />
            </IconButton>
            <IconButton>
              <YoutubeIcon onClick={handleYoutubeClick}  sx={{ mt: 1, ml: -5 }}/>
            </IconButton>

          </Box>
        </Box>
        <Box sx={{ width: "100%", display: "flex", flexDirection: { xs: "column", md: "row" }, gap: { xs: "10px", md: "0px " } }}>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "#fff" }}>Useful Links</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography sx={{ fontSize: { xs: "12px", md: "14px" }, color: "#fff",cursor:"pointer" }} onClick={aboutClick}>About Us</Typography>
              <Typography sx={{ fontSize: { xs: "12px", md: "14px" }, color: "#fff",cursor:"pointer" }} onClick={faqClick} >FAQ</Typography>
              <Typography sx={{ fontSize: { xs: "12px", md: "14px" }, color: "#fff",cursor:"pointer"}} onClick={handlePrivacyClick}>Privacy Policy</Typography>
              
              <Typography sx={{ fontSize: { xs: "12px", md: "14px" }, color: "#fff",cursor:"pointer" }} onClick={handleRefundClick}>Refund Policy</Typography>
             
              <Typography sx={{ fontSize: { xs: "12px", md: "14px" }, color: "#fff",cursor:"pointer" }} onClick={termsAndConditionsClick} >Terms & Conditions</Typography>
            </Box>
          </Box>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "#fff" }}>Reachout To Us</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              <Typography sx={{ fontSize: { xs: "12px", md: "14px" }, color: "#fff" }} >Get more potential updates on Investment News in Agriculture</Typography>
              <TextField
                placeholder="Enter your email here..."
                size='small'
                // type="email"
                // value={email}
                // onChange={handleChange}
                type="email"
        // value={email}
        // onChange={handleEmailChange}
        value={email}
        onChange={handleEmailChange}

                InputProps={{
                  endAdornment: (
                    <IconButton sx={{ background: "#28947F", '&:hover': { background: "#F7C35F", p: { xs: 1, md: 1 } } }} onClick={handleButtonClick}>
                      <Typography sx={{ fontSize: "10px", color: "#fff", fontWeight: "bold", '&:hover': { color: "#28947F" } }}>GO</Typography>

                    </IconButton>
                  ),
                }}
                sx={{
                  borderRadius: { xs: "40px", md: "50px" }, width: { sm: '50%', xs: '100%', md: '100%' }, background: "#263C28", size: { xs: "medium", md: "small" }, p: { xs: "4px", md: "2px" }, color: "#fff", '& fieldset': {
                    border: 'none',

                  },
                  '& input::placeholder': {
                    color: '#fff',
                    fontSize: { xs: "12px", md: '14px' },
                  },
                  '& .MuiOutlinedInput-root': {
                    color: "#fff"
                  }
                }}
              />
              {emailSubmit && (
        <Typography variant="body2" color={emailError ? "error" : "primary"}>
          {emailError && "Invalid email address"}
        </Typography>
      )}

            </Box>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ background: "#fff" }} />

      <Typography sx={{ fontSize: "12px", color: "#fff", textAlign: "center", p: 2 }}>Copyright © InvestoFarms. All Right Reserved.2024</Typography>

    </Box>
  );
};

export default Footer;
