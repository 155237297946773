import React, { useState } from "react";
import {
  Box,
  Slider,
  Typography,
  Button,
  CircularProgress,
  Card,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function Calculator() {
  const [investment, setInvestment] = useState(25000);
  const [tenure, setTenure] = useState(1);
  const [returnRate, setReturnRate] = useState(5);
  const navigate = useNavigate();

  const handleInvest = () =>
     {
       navigate("/disclaimer")
     }
 

  const mapReturnRateToPercentage = {
    15:15,
    5: 15,
    13: 20,
    22: 25,
    30: 30,
  };
  const calculateReturns = () => {
    const percentage = mapReturnRateToPercentage[returnRate];
    return investment * (percentage / 100) * tenure;
  };

  const totalAmount = investment + calculateReturns();

  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: 520,
        display: "flex",
        gap: "16px",
        p: 3,
        borderRadius: "16px",
        borderWidth: "0.5px",
        borderColor:"#28947f",
        transform:{xs: 'scale(0.9)',md: 'scale(0.85)'}
        // boxShadow: `rgba(40, 148, 127, 0.2) 0px 2px 8px 0px`,
      }}
    >
      <Box
        sx={{
          width: "100%",
          // p: 1,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: "60px",
              }}
            >
              <Typography
                sx={{ fontSize: "12px", fontWeight: "bold", color: "#28947F" }}
              >
                Total Investment
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#28947F",
                  background: "#e0f7fa",
                  borderRadius: "2px",
                  width: "66px",
                  textAlign: "right",
                  p: "1px",
                }}
              >
                {investment.toLocaleString()}
              </Typography>
            </Box>
            <Slider
              value={investment}
              onChange={(e, val) => setInvestment(val)}
              step={null}
              min={0}
              max={100000}
              // valueLabelDisplay="auto"
              marks={[
                { value: 25000, label: "25K" },
                { value: 50000, label: "50K" },
                { value: 75000, label: "75K" },
                { value: 100000, label: "1L" },
              ]}
              sx={{
                "& .MuiSlider-rail": {
                  background: "grey",
                  height: 3,
                  color: "#28947F",
                },
                "& .MuiSlider-track": {
                  height: 0,
                  color: "#28947F",
                },
                "& .MuiSlider-thumb": {
                  height: 16,
                  width: 16,
                  backgroundColor: "#28947F",
                  "&:hover, &.Mui-focusVisible, &.Mui-active": {
                    boxShadow: "none",
                  },
                },

                "& .MuiSlider-markLabel": {
                  fontSize: "8px",
                  fontWeight: "bold",
                  top: -35,
                  color: "#28947F",
                },
                "& .MuiSlider-mark": {
                  height: 12,
                  width: 2,
                  top: -15,
                  backgroundColor: "#28947F",
                },
              }}
            />
          </Box>

          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: "60px",
              }}
            >
              <Typography
                sx={{ fontSize: "12px", fontWeight: "bold", color: "#28947F" }}
              >
                Tenure
              </Typography>

              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#28947F",
                  background: "#e0f7fa",
                  borderRadius: "2px",
                  width: "66px",
                  textAlign: "right",
                  p: "1px",
                }}
              >
                {tenure} Year{tenure > 1 ? "s" : ""}
              </Typography>
            </Box>
            <Slider
              value={tenure}
              onChange={(e, val) => setTenure(val)}
              step={null}
              min={0}
              max={5}
              // valueLabelDisplay="auto"
              marks={[
                { value: 1, label: "1Y" },
                { value: 2, label: "2Y" },
                { value: 3, label: "3Y" },
                { value: 4, label: "4Y" },
                { value: 5, label: "5Y" },
              ]}
              sx={{
                "& .MuiSlider-rail": {
                  background: "grey",
                  height: 3,
                  color: "#28947F",
                },
                "& .MuiSlider-track": {
                  height: 0,
                  color: "#28947F",
                },
                "& .MuiSlider-thumb": {
                  height: 16,
                  width: 16,
                  backgroundColor: "#28947F",
                  "&:hover, &.Mui-focusVisible, &.Mui-active": {
                    boxShadow: "none",
                  },
                },

                "& .MuiSlider-markLabel": {
                  fontSize: "8px",
                  fontWeight: "bold",
                  top: -35,
                  color: "#28947F",
                },
                "& .MuiSlider-mark": {
                  height: 12,
                  width: 2,
                  top: -15,
                  backgroundColor: "#28947F",
                },
              }}
            />
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: "60px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#28947F",
                  }}
                >
                  Expected Return Rate (p.a)
                </Typography>

                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#28947F",
                    background: "#e0f7fa",
                    borderRadius: "2px",
                    width: "66px",
                    textAlign: "right",
                    p: "1px",
                  }}
                >
                  {mapReturnRateToPercentage[returnRate]}%
                </Typography>
              </Box>
              <Slider
                value={returnRate}
                onChange={(e, val) => setReturnRate(val)}
                step={null}
                min={0}
                max={30}
                // valueLabelDisplay="auto"
                marks={[
                  { value: 5, label: "15%" },
                  { value: 13, label: "20%" },
                  { value: 22, label: "25%" },
                  { value: 30, label: "30%" },
                ]}
                sx={{
          "& .MuiSlider-rail": {
                  background: "grey",
                  height: 3,
                  color: "#28947F",
                },
                "& .MuiSlider-track": {
                  height: 0,
                  color: "#28947F",
                },
                "& .MuiSlider-thumb": {
                  height: 16,
                  width: 16,
                  backgroundColor: "#28947F",
                  "&:hover, &.Mui-focusVisible, &.Mui-active": {
                    boxShadow: "none",
                  },
                },

                "& .MuiSlider-markLabel": {
                  fontSize: "8px",
                  fontWeight: "bold",
                  top: -35,
                  color: "#28947F",
                },
                "& .MuiSlider-mark": {
                  height: 12,
                  width: 2,
                  top: -15,
                  backgroundColor: "#28947F",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: "10px", fontWeight: "medium", color: "#717171" }}
            >
              Invested Amount
            </Typography>
            <Typography
              sx={{ fontSize: "10px", fontWeight: "medium", color: "#717171" }}
            >
              {" "}
              {investment.toLocaleString()}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: "10px", fontWeight: "medium", color: "#717171" }}
            >
              Estimated Returns{" "}
            </Typography>
            <Typography
              sx={{ fontSize: "10px", fontWeight: "medium", color: "#717171" }}
            >
              {" "}
              {calculateReturns().toLocaleString()}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: "10px", fontWeight: "medium", color: "#000" }}
            >
              Total Amount{" "}
            </Typography>
            <Typography
              sx={{ fontSize: "10px", fontWeight: "medium", color: "#000" }}
            >
              {" "}
              {totalAmount.toLocaleString()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // p: 1,
          alignItems: "center",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
            <Box
              sx={{
                width: "20px",
                height: "8px",
                borderRadius: "8px",
                background: "#D9D9D9",
              }}
            />
            <Typography
              sx={{ fontSize: "8px", fontWeight: "bold", color: "#D9D9D9" }}
            >
              Invested Amount
            </Typography>
            <Box
              sx={{
                width: "20px",
                height: "8px",
                borderRadius: "8px",
                background: "#28947F",
              }}
            />
            <Typography
              sx={{ fontSize: "8px", fontWeight: "bold", color: "#28947F" }}
            >
              Return
            </Typography>
          </Box>
        </Box>
        <Box>
        <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          value={100} // Always full circle for invested amount
          size={150}
          thickness={4}
          sx={{ color: "#D9D9D9" }} // Grey color
        />
        <CircularProgress
          variant="determinate"
          value={(calculateReturns() / totalAmount) * 100} // Green portion for returns
          size={150}
          thickness={4}
          sx={{
            color: "#28947F",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      </Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              color: "#000",
              textAlign: "center",
              mt: "30px",
            }}
          >
            TAX FREE
          </Typography>
        </Box>

        <Box>
          <Button
            variant="contained"
            fullWidth
            sx={{
              background: "#28947F",
              borderRadius: "25px",
              width: "125px",
              height: "40px",
              p: 1,
              "&:hover": {
              background: "#28947F", 
    },
            }}
            onClick={handleInvest}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: "medium" }}>
              INVEST NOW
            </Typography>
          </Button>
        </Box>
      </Box>
    </Card>
  );
}

export default Calculator;