import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as CheckCircleIcon } from '../../assets/images/CheckedIcon.svg';

const features = [
    {
      title: "Diverse Opportunities",
      description: "Access a wide range of vetted agricultural projects to diversify and balance your portfolio."
    },
    {
      title: "Comprehensive Support",
      description: "Benefit from educational resources, expert advice, and advanced tools for informed investment decisions."
    },
    {
      title: "Transparency and Trust",
      description: "Enjoy secure transactions, detailed reporting, and clear sustainability metrics for confident investing."
    }
  ];

function InvestorInfo() {
    return (
      <Box>
        {features.map((feature, index) => (
          <Box key={index}  sx={{display:"flex", alignItems:"center", gap:"12px"}}>
          <Box >
            <CheckCircleIcon />
            </Box>
            <Box mt="14px">
              <Typography sx={{fontWeight:"500", fontSize:"14px", width:{xs:"90%",md:"95%"},}}>
               <span style={{fontWeight:"bold"}}>{feature.title}</span>  : {feature.description}
              </Typography>    
            </Box>
          </Box>
        ))}
      </Box>
    );
  }

export default InvestorInfo;
